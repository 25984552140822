import React, { useEffect, useRef } from 'react';
import './Section.css';
import Home from './Home';
import Ecosystem from './Ecosystem';
import NFTCollection from './NFTCollection';
import Chart from './QrCodeModal';
import Vault from './Vault';
import Settings from './Settings';

function Section({ category }) {
  const sectionRef = useRef(null);

  useEffect(() => {
    if (sectionRef.current) {
      sectionRef.current.scrollTo(0, 0);
    }
  }, [category]);

  const renderContent = () => {
    switch (category) {
      case 'Home':
        return <Home />;
      case 'Ecosystem Info':
        return <Ecosystem />;
      case 'NFT Marketplace':
        return <NFTCollection />;
      case 'Chart':
        return <Chart />;
      case 'Vault':
        return <Vault />;
      case 'Settings':
        return <Settings />;
      default:
        return <p>Content for {category}</p>;
    }
  };

  return (
    <div className="section" ref={sectionRef}>
      {renderContent()}
    </div>
  );
}

export default Section;
