import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChainFiVaultFactory_ABI from "./Contracts/ChainFiVaultFactory.json";
import Ecosystem from './Ecosystem';
import QrCodeModal from './QrCodeModal';

const expectedChainId = "0x14a34"; 
const rpcUrl = "wss://base-sepolia-rpc.publicnode.com"; 

const networkConfig = {
    chainId: expectedChainId,
    chainName: "Base Sepolia Testnet",
    rpcUrls: [rpcUrl],
    nativeCurrency: {
        name: "SepoliaETH",
        symbol: "ETH",
        decimals: 18,
    },
    blockExplorerUrls: ["https://base-sepolia.blockscout.com"],
};

const Vault = () => {
  const [provider, setProvider] = useState(null);
  const [vaultAddress, setVaultAddress] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showVaultModal, setShowVaultModal] = useState(false);
  const [show2FAModal, setShow2FAModal] = useState(false);
  const [fallbackOwner, setFallbackOwner] = useState('');
  const [_2faContract, set2faContract] = useState('');
  const payableAmount = '0.0006'; // Static fee amount

  window.userAddress = window.userAddress || '';

  useEffect(() => {
    const connectWallet = async () => {
      try {
        if (window.ethereum) {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          let web3Provider = new ethers.BrowserProvider(window.ethereum);
          const accounts = await web3Provider.listAccounts();
          let network = await web3Provider.getNetwork();

          if (network.chainId.toString() !== parseInt(expectedChainId, 16).toString()) {
            await switchToExpectedNetwork(web3Provider);
            network = await web3Provider.getNetwork();
            if (network.chainId.toString() !== parseInt(expectedChainId, 16).toString()) {
              throw new Error('Failed to switch to the correct network.');
            }
          }

          setProvider(web3Provider);
          window.userAddress = accounts[0];

          window.ethereum.on('accountsChanged', (accounts) => {
            window.userAddress = accounts[0];
            checkIfVault();
          });

        } else {
          toast.error('MetaMask is not installed. Please install it to use this feature.');
        }
      } catch (error) {
        toast.error(error.message || 'Error connecting to wallet.');
      } finally {
        setIsLoading(false);
      }
    };

    const switchToExpectedNetwork = async (provider) => {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [networkConfig],
        });
      } catch (error) {
        console.error('Error switching network:', error);
        throw error;
      }
    };

    connectWallet();
  }, []);

  const checkIfVault = async () => {
    if (!window.userAddress || !provider) {
      return;
    }

    const factoryAddress = "0xE7ca296BB04430eb1872D131770dd31e3d1c68CA";
    const factoryContract = new ethers.Contract(factoryAddress, ChainFiVaultFactory_ABI, provider);

    try {
      const vault = await factoryContract.getUserVault(window.userAddress);
      if (vault && vault !== ethers.ZeroAddress) {
        setVaultAddress(vault);
      }
    } catch (error) {
      toast.error('Error checking if address is a vault');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (provider) {
      checkIfVault();
    }
  }, [provider]);

  const handle2FASetup = () => {
    setShow2FAModal(true); // Open QR code modal to get the _2faContract address
  };

  const handleSignedMessage = (content) => {
    set2faContract(content); // Set _2faContract address from QR code
    setShow2FAModal(false); // Close QR modal
  };

  const createVault = async () => {
    if (!window.ethereum) {
      toast.error('MetaMask is not installed!');
      return;
    }

    try {
      // Request account and provider setup
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const accounts = await provider.listAccounts();
      const connectedAccount = accounts[0].address; // Access the first account in the array

      if (!fallbackOwner || fallbackOwner.toLowerCase() == connectedAccount.toLowerCase()) {
        toast.error('The fallback owner address must not match the connected wallet.');
        return;
      }

      if (!_2faContract || _2faContract.length !== 42) {
        toast.error('Invalid 2FA contract address.');
        return;
      }

      let network = await provider.getNetwork();
      if (network.chainId.toString() !== parseInt(expectedChainId, 16).toString()) {
        toast.error('Incorrect network. Please switch to Base Sepolia.');
        return;
      }

      const factoryAddress = "0xE7ca296BB04430eb1872D131770dd31e3d1c68CA"; 
      const aggregatorContract = new ethers.Contract(factoryAddress, ChainFiVaultFactory_ABI, signer);

      const tx = await aggregatorContract.createVault(
        fallbackOwner,
        _2faContract,
        { value: ethers.parseEther(payableAmount) }
    );
      
      await tx.wait();
      toast.success('Vault created successfully!');
      window.location.reload();
      setShowVaultModal(false);
    } catch (error) {
      toast.error('Error creating vault');
      console.error('Error:', error);
    }
};


  if (isLoading) {
    return <div>Loading vault data...</div>;
  }

  return (
    <div className="settings-container">
      <ToastContainer />
      {(vaultAddress && !showVaultModal && !show2FAModal) ? (
          <Ecosystem vaultAddress={vaultAddress} />
      ) : (
          !showVaultModal && !show2FAModal && (
              <div className="no-vault-section">
                  <h2 className="settings-title">No Vault Found</h2>
                  <p>You currently do not have a vault. Please create one by clicking the button below.</p>
                  <button className="settings-button" onClick={() => setShowVaultModal(true)}>Create Vault</button>
              </div>
          )
      )}

      {showVaultModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            {!show2FAModal && (
              <>
                <h3>Vault Creation</h3>
                <p className="fee-info">A small fee of {payableAmount} ETH is required for vault creation.</p>
                <input
                  type="text"
                  value={fallbackOwner}
                  onChange={(e) => setFallbackOwner(e.target.value)}
                  placeholder="Fallback Owner Address"
                  className="verification-input"
                />
                
                {_2faContract ? (
                  <h4 className="setup-complete-message">2FA Setup Complete</h4>
                ) : (
                  <button 
                    className="settings-button"
                    onClick={handle2FASetup}
                  >
                    Set Up 2FA
                  </button>
                )}
              </>
            )}
            
            {show2FAModal && (
              <QrCodeModal
                isOpen={show2FAModal}
                onClose={() => setShow2FAModal(false)}
                signInMessage="get-wallet"
                onSignedMessage={handleSignedMessage}
              />
            )}

            {!show2FAModal && (
              <div className="settings-buttons">
                <button className="modal-close-button" onClick={() => setShowVaultModal(false)}>Cancel</button>
                <button className="modal-close-button" onClick={createVault}>Create Vault</button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Vault;
