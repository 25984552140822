import React, { useState } from 'react';
import './Navbar.css';

function Navbar({ onSelectCategory }) {
  const [activeCategory, setActiveCategory] = useState('Vault');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    onSelectCategory(category);
    if (window.innerWidth <= 768) {
      setIsMenuOpen(false); // Close menu on mobile after selection
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const categories = [
    'Home',
    'Vault',
  ];

  return (
    <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
      <h1 className="navbar-title">Menu</h1>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <ul className={`navbar-nav ${isMenuOpen ? 'show' : ''}`}>
        {categories.map((category) => (
          <li key={category}>
            <button
              className={activeCategory === category ? 'active' : ''}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;
