import React, { useState } from 'react';
import WalletConnect from './WalletConnect';
import "./Header.css";

function Header() {
  const [reload, setReload] = useState(0);

  const onWalletConnected = (signer) => {
    console.log("Wallet has been connected", signer);
    setReload(prev => prev + 1); 
  };

  return (
    <div className="header">
      <WalletConnect onWalletConnected={onWalletConnected} />
    </div>
  );
}

export default Header;
