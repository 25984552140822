import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import io from 'socket.io-client';
import './QrCodeModal.css';

const QrCodeModal = ({ isOpen, onClose, signInMessage, onSignedMessage }) => {
    const [qrData, setQrData] = useState('');
    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isOpen) {
            createSession();
        }
        // Cleanup only when the modal is closed
        return () => {
            if (!isOpen) {
                cleanupSocket();
            }
        };
    }, [isOpen]);

    const createSession = () => {
        if (socket) return; // Prevent duplicate socket connections
        const newSocket = io('https://bitbrand.net'); // Replace with your VPS address
        setSocket(newSocket);
        setLoading(true);

        newSocket.on('connect', () => {
        });

        newSocket.on('connect_error', (error) => {
            console.error('Connection error:', error);
            handleModalClose(); // Close the modal on connection error
        });

        newSocket.on('session-created', (id) => {
            setQrData(id);
            setLoading(false);
            newSocket.emit('join-session', id);
        });

        newSocket.on('message', ({ sender, content }) => {
            if (onSignedMessage && sender !== 'You') {
                onSignedMessage(content);
                handleModalClose(); // Close the modal upon receiving a signed message
            }
        });

        newSocket.on('device-joined', (message, sessionId) => {
            sendMessage(newSocket, sessionId); // Send the provided signInMessage
        });

        newSocket.on('session-closed', (message) => {
            handleModalClose(); // Close the modal when the session is closed
        });

        newSocket.emit('create-offer', { message: signInMessage || "Session creation request" });
    };

    const sendMessage = (socket, sessionId) => {
        if (socket && sessionId) {
            socket.emit('message', { sessionId, content: signInMessage });
        } else {
            console.warn("Socket or sessionId is missing; cannot send message.");
        }
    };

    const cleanupSocket = () => {
        if (socket && qrData) {
            socket.emit('cancel-session', qrData);
        }
        socket?.off('session-created');
        socket?.off('message');
        socket?.off('device-joined');
        socket?.off('session-closed');
        socket?.disconnect();
        setSocket(null);
    };

    const handleModalClose = () => {
        cleanupSocket();
        resetSession();
        onClose();
    };

    const resetSession = () => {
        setQrData('');
        setSocket(null);
        setLoading(true);
    };

    if (!isOpen) return null;

    return (
        <div>
            <div className="qr-generator-content">
                {loading && <p>Loading QR code...</p>}

                {!loading && qrData && (
                    <div className="qr-code-display">
                        <h3>Scan this QR code with the app</h3>
                        <QRCodeCanvas value={qrData} size={300} level="L" />
                    </div>
                )}
            </div>
            <button className="modal-close-button" onClick={handleModalClose}>Close</button>
        </div>
    );
};

export default QrCodeModal;
