import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';

const WalletConnect = ({ onWalletConnected }) => {
  const [isConnecting, setIsConnecting] = useState(true);
  const [userAddress, setUserAddress] = useState('');
  const [walletConnected, setWalletConnected] = useState(false);
  const expectedChainId = "0x14a34"; // Set your expected network's chain ID for Base Sepolia
  const rpcUrl = "wss://base-sepolia-rpc.publicnode.com"; // RPC URL for Base Sepolia

  const networkConfig = {
    chainId: expectedChainId,
    chainName: "Base Sepolia Testnet",
    rpcUrls: [rpcUrl],
    nativeCurrency: {
      name: "SepoliaETH",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://base-sepolia.blockscout.com"],
  };

  const connectWallet = async () => {
    if (isConnecting) return;
    setIsConnecting(true);
    try {
      if (!window.ethereum) {
        throw new Error('MetaMask is not installed');
      }

      await window.ethereum.request({ method: 'eth_requestAccounts' });
      let provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const accounts = await provider.listAccounts();
      let network = await provider.getNetwork();

      if (network.chainId.toString() !== parseInt(expectedChainId, 16).toString()) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [networkConfig],
        });
        provider = new ethers.BrowserProvider(window.ethereum);
        network = await provider.getNetwork();
        if (network.chainId.toString() !== parseInt(expectedChainId, 16).toString()) {
          console.log('Failed to switch to the correct network.');
          return;
        }
      }

      if (accounts.length === 0) throw new Error("No account is connected");
      setUserAddress(accounts[0]);
      console.log("Wallet connected:", accounts[0]);
      onWalletConnected(signer);
      setWalletConnected(true);

      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('disconnect', handleDisconnect);
    } catch (error) {
      console.error('Error connecting to wallet:', error);
      alert(error.message); // Alert the user about the network issue or other errors
    } finally {
      setIsConnecting(false);
    }
  };

  const handleAccountsChanged = useCallback((accounts) => {
    if (accounts.length === 0) {
      handleDisconnect();
    } else {
      setUserAddress(accounts[0]);
      setWalletConnected(false);
      connectWallet();
    }
  }, [connectWallet]);

  const handleDisconnect = useCallback(() => {
    setWalletConnected(false);
    setUserAddress('');
    console.log('Wallet disconnected');
  }, []);

  useEffect(() => {
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('disconnect', handleDisconnect);
      }
    };
  }, [handleAccountsChanged, handleDisconnect]);

  useEffect(() => {
    const checkInitialConnection = async () => {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        if (accounts.length > 0) {
          setUserAddress(accounts[0]);
          setWalletConnected(true);
        }
        setIsConnecting(false);
      }
    };
    checkInitialConnection();
  }, []);

  return (
    <div className="modal">
      {isConnecting && (
        <button className="wallet-info" disabled={isConnecting}>
          Loading...
        </button>
      )}
      {!walletConnected && !isConnecting && (
        <button className="wallet-info" onClick={connectWallet} disabled={isConnecting}>
          Connect Wallet
        </button>
      )}
      {walletConnected && !isConnecting && (
        <p className="wallet-info">
          {`Welcome, ${userAddress.slice(0, 6)}...${userAddress.slice(-4)}`}
        </p>
      )}
    </div>
  );
};

export default WalletConnect;
