// Settings.js
import React, { useState } from 'react';
import './Settings.css';

const Settings = () => {
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState('');

  const username = '0x2131231';

  const handleSetup2FA = async () => {
    try {
      const response = await fetch('https://bitbrand.net/api/2fa/setup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setQrCodeUrl(data.qrCodeDataURL);
        setMessage('Scan the QR code with your authenticator app.');
        setIsSetupModalOpen(true);
      } else {
        setMessage(data.message || 'Error setting up 2FA.');
      }
    } catch (error) {
      console.error('Error setting up 2FA:', error);
      setMessage('Error setting up 2FA. Please try again.');
    }
  };

  const handleVerify2FA = async () => {
    try {
      const response = await fetch('https://bitbrand.net/api/2fa/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          token: verificationCode,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('2FA enabled successfully!');
      } else {
        setMessage(data.message || 'Invalid 2FA code. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying 2FA:', error);
      setMessage('Invalid 2FA code. Please try again.');
    }
  };

  return (
    <div className="settings-container">
      <h2 className="settings-title">Settings</h2>
      <div className="settings-buttons">
        <button onClick={handleSetup2FA} className="settings-button">
          Set Up 2FA
        </button>
        <button onClick={() => setIsCheckModalOpen(true)} className="settings-button">
          Check 2FA
        </button>
      </div>

      {/* Setup 2FA Modal */}
      {isSetupModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Set Up 2FA</h3>
            <p>{message}</p>
            {qrCodeUrl ? (
              <div><img src={qrCodeUrl} alt="Scan QR code with your authenticator app" /></div>
            ) : (
              <div className="qr-placeholder">
                <p>Loading QR Code...</p>
              </div>
            )}
            <button
              onClick={() => {
                setIsSetupModalOpen(false);
                setMessage('');
                setQrCodeUrl('');
              }}
              className="modal-close-button"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Check 2FA Modal */}
      {isCheckModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Check 2FA</h3>
            <div className="verification-container">
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="Enter 6-digit code"
                value={verificationCode}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only digits
                  if (/^\d*$/.test(value)) {
                    setVerificationCode(value);
                  }
                }}
                onKeyDown={(e) => {
                  // Allow control keys (backspace, arrow keys, etc.)
                  if (
                    e.key !== 'Backspace' &&
                    e.key !== 'ArrowLeft' &&
                    e.key !== 'ArrowRight' &&
                    e.key !== 'Delete' &&
                    !/^[0-9]$/.test(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                className="verification-input"
                maxLength="6"
              />
              <button onClick={handleVerify2FA} className="settings-button">
                Verify Code
              </button>
            </div>
            {message && <p>{message}</p>}
            <button
              onClick={() => {
                setIsCheckModalOpen(false);
                setMessage('');
                setVerificationCode('');
              }}
              className="modal-close-button"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
