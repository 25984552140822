/* global BigInt */
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import './Ecosystem.css';
import ethereumLogo from './images/ethereum.png';
import binanceLogo from './images/bnb.png';
import polygonLogo from './images/polygon.png';
import baseLogo from './images/base.png';
import ChainFiVault_ABI from "./Contracts/ChainFiVault.json";
import { ethers, BrowserProvider, Contract, EtherscanProvider } from 'ethers';
import QrCodeModal from './QrCodeModal'; 
import moment from 'moment';
import { toast } from "react-toastify";

const Ecosystem = ({ vaultAddress }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [activeTab, setActiveTab] = useState('deposit');
    const [mode, setMode] = useState('tokens');
    const [selectedNFT, setSelectedNFT] = useState(null);
    const [recipientInput, setRecipientInput] = useState("");
    const handleInputChange = (e) => {
        setRecipientInput(e.target.value);
    };
    const tokenLists = {
        Ethereum: [
            { address: '0x0000000000000000000000000000000000000000', symbol: 'ETH' }, // Native ETH token
        ],
        Binance: [
            { address: '0x0000000000000000000000000000000000000000', symbol: 'BNB' }, // Native BNB token
        ],
        Polygon: [
            { address: '0x0000000000000000000000000000000000000000', symbol: 'MATIC' }, // Native MATIC token
        ],
        Base: [
            { address: '0x0000000000000000000000000000000000000000', symbol: 'ETH' },  // ETH token on Base
            { address: '0x52Fa679fDeC3E03eA9f3584a1FA0955678ED9222', symbol: 'RTN' }   // RogueToken (RTN)
        ],
    };

    const getTokenSymbol = (tokenAddress, network, fallbackSymbol) => {
        const tokenList = tokenLists[network];
        const token = tokenList?.find(t => t.address.toLowerCase() === tokenAddress.toLowerCase());
        return token ? token.symbol : "Unknown";
    };
    
    const [amountforContract, setAmountForContract] = useState("");

    const [selectedBlockchain, setSelectedBlockchain] = useState(
        localStorage.getItem('selectedBlockchain') || 'Base'
    );
    const [selectedToken, setSelectedToken] = useState(
        localStorage.getItem('selectedToken') || 'RTN'
    );

    const [vaultData, setVaultData] = useState({});

    const baseTokens = {
        Ethereum: 'ETH',
        Binance: 'BNB',
        Polygon: 'MATIC',
        Base: 'ETH'
    };

    const blockchainParams = {
        Ethereum: {
            chainId: '0x1',
            chainName: 'Ethereum Mainnet',
            nativeCurrency: {
                name: 'Ether',
                symbol: 'ETH',
                decimals: 18,
            },
            rpcUrls: ['https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID'],
            blockExplorerUrls: ['https://etherscan.io'],
        },
        Binance: {
            chainId: '0x38',
            chainName: 'Binance Smart Chain',
            nativeCurrency: {
                name: 'Binance Coin',
                symbol: 'BNB',
                decimals: 18,
            },
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
            blockExplorerUrls: ['https://bscscan.com'],
        },
        Polygon: {
            chainId: '0x89',
            chainName: 'Polygon Mainnet',
            nativeCurrency: {
                name: 'Matic',
                symbol: 'MATIC',
                decimals: 18,
            },
            rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
            blockExplorerUrls: ['https://polygonscan.com'],
        },
        Base: {
            chainId: '0x14a34', // 84531
            chainName: 'Base Sepolia Testnet',
            nativeCurrency: {
                name: 'SepoliaETH',
                symbol: 'ETH',
                decimals: 18,
            },
            rpcUrls: ['wss://base-sepolia-rpc.publicnode.com'],
            blockExplorerUrls: ['https://base-sepolia.blockscout.com'],
        },
    };

    const [selectedTimePeriod, setSelectedTimePeriod] = useState('1d');

    useEffect(() => {
        const updateChart = () => {
            const myChartRef = chartRef.current?.getContext('2d');
            if (!myChartRef) {
                console.warn('No chart context available');
                return;
            }
    
            // Destroy existing chart instance if it exists
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
    
            const now = moment();
            const labels = [];
            const groupedData = {};
    
            const tokenBalanceObj = vaultData.tokenBalances.find(token => token.symbol === selectedToken);
            const initialBalance = tokenBalanceObj ? parseFloat(tokenBalanceObj.balance) : 0;
    
            // Determine time slots based on selected time period
            if (selectedTimePeriod === '1d') {
                const startOfDay = now.clone().startOf('day');
                const currentHour = now.hour();
                const intervalsPassed = Math.floor(currentHour / 2);
    
                for (let i = 0; i <= intervalsPassed; i++) {
                    const label = startOfDay.clone().add(i * 2, 'hours').format('HH:mm');
                    labels.push(label);
                    groupedData[label] = initialBalance; // Initialize with basic amount
                }
            } else if (selectedTimePeriod === '7d') {
                for (let i = 6; i >= 0; i--) {
                    const label = now.clone().startOf('day').subtract(i, 'days').format('MM-DD');
                    labels.push(label);
                    groupedData[label] = initialBalance; // Initialize with basic amount
                }
            }  else if (selectedTimePeriod === '30d') {
                for (let i = 29; i >= 0; i--) {
                    const label = now.clone().startOf('day').subtract(i, 'days').format('MM-DD');
                    labels.push(label);
                    groupedData[label] = initialBalance; // Initialize with basic amount
                }
            }
            
    
            // Filter the transactions that are relevant to the selected token and time period
            const transactionData = (vaultData.transactionData || []).filter(tx => {
                const tokenSymbol = getTokenSymbol(tx.contractAddress, 'Base', tx.tokenSymbol);
    
                if (tokenSymbol === "Unknown" || tx.tokenSymbol !== selectedToken) {
                    return false;
                }
    
                const transactionMoment = moment.unix(tx.timeStamp);
                if (selectedTimePeriod === '1d') {
                    return transactionMoment.isSame(moment(), 'day');
                } else if (selectedTimePeriod === '7d') {
                    return transactionMoment.isAfter(moment().subtract(7, 'days'), 'day');
                } else if (selectedTimePeriod === '30d') {
                    return transactionMoment.isAfter(moment().subtract(30, 'days'), 'day');
                }
    
                return false;
            });
    
            // Reverse labels for processing from the last to first slot
            labels.reverse();
    
            // Start with the initial balance for the last slot
            let runningBalance = initialBalance;
    
            labels.forEach((label, index) => {
                groupedData[label] = runningBalance; // Set the initial balance for the current slot
    
                // Accumulate transactions for previous slots
                transactionData.forEach(transaction => {
                    const txTime = moment.unix(transaction.timeStamp)
                        .startOf(selectedTimePeriod === '1d' ? 'hour' : 'day')
                        .format(selectedTimePeriod === '1d' ? 'HH:mm' : 'MM-DD');
    
                    if (txTime === label) {
                        const amount = parseFloat(transaction.value) / Math.pow(10, transaction.tokenDecimal);
                        if (transaction.to.toLowerCase() === vaultAddress.toLowerCase()) {
                            runningBalance -= amount;
                        } else if (transaction.from.toLowerCase() === vaultAddress.toLowerCase()) {
                            runningBalance += amount;
                        }
                    }
                });
    
            });
    
            labels.reverse(); // Restore label order for chart creation
    
            // Map filtered data based on updated groupedData
            const filteredData = labels.map((label) => groupedData[label]);
    
            // Create the chart instance
            chartInstance.current = new Chart(myChartRef, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [{
                        label: `${selectedToken} Balance`,
                        data: filteredData,
                        backgroundColor: 'rgba(5, 28, 175, 0.3)',
                        borderColor: 'rgba(25, 48, 202, 1)',
                        borderWidth: 3,
                        fill: true,
                        tension: 0.3,
                        pointRadius: 5,
                        pointHoverRadius: 7,
                        pointBackgroundColor: 'rgba(25, 48, 202, 1)',
                        pointBorderColor: 'white',
                        pointBorderWidth: 2
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                color: 'white',
                                font: {
                                    size: 14
                                }
                            }
                        },
                        x: {
                            ticks: {
                                color: 'white',
                                font: {
                                    size: 14
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: 'white',
                                font: {
                                    size: 14
                                }
                            },
                        },
                        tooltip: {
                            titleColor: 'white',
                            bodyColor: 'white'
                        }
                    },
                    maintainAspectRatio: false,
                    interaction: {
                        mode: 'nearest',
                        intersect: false
                    }
                }
            });
        };
    
        // Wait for tokens to be properly loaded before updating the chart
        if (selectedToken && vaultData.transactionData) {
            updateChart();
        }
    
    }, [vaultData.transactionData, selectedToken, selectedTimePeriod, vaultAddress, getTokenSymbol]);
    
    const fetchVaultData = async () => {
        try {
            if (typeof window.ethereum !== 'undefined') {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
    
                const provider = new BrowserProvider(window.ethereum);
                const nftData = [];
    
                const fetchUserTokenBalances = async (userAddress) => {
                    try {
                        const balances = [];
                        for (const token of tokenLists[selectedBlockchain]) {
                            try {
                                let balance;
                                if (token.address === '0x0000000000000000000000000000000000000000') {
                                    // Fetch native token balance
                                    balance = await provider.getBalance(userAddress);
                                } else {
                                    // Fetch ERC-20 token balance using `balanceOf`
                                    const tokenContract = new Contract(token.address, [
                                        "function balanceOf(address owner) view returns (uint256)"
                                    ], provider);
                                    balance = await tokenContract.balanceOf(userAddress);
                                }
                                
                                // Convert to ether and round to 4 decimal places
                                const formattedBalance = parseFloat(ethers.formatEther(balance)).toFixed(4);
                
                                balances.push({
                                    symbol: token.symbol,
                                    balance: formattedBalance,
                                });
                            } catch (error) {
                                console.error(`Failed to fetch balance for ${token.symbol}:`, error);
                                balances.push({
                                    symbol: token.symbol,
                                    balance: "0.00000",  // Show zero balance with four decimal places
                                });
                            }
                        }
                        return balances;
                    } catch (error) {
                        console.error('Error fetching user token balances:', error);
                        return [];
                    }
                };
                
    
                const getTransactionData = async (address) => {
                    const transactions = [];
    
                    try {
                        // Replace with the new URL to your custom server endpoint for token transfers
                        const response = await fetch(`https://bitbrand.net/api/token-transfers/${address}`);
                        const data = await response.json();
    
                        if (data.status === 'success') {
                            data.data.forEach((tx) => {
                                transactions.push(tx);
                            });
                        } else {
                            console.error("Error fetching token transfer history:", data.message);
                        }
                    } catch (error) {
                        console.error("Error fetching token transfer history:", error);
                    }
    
                    return transactions;
                };
    
                const tokenBalances = await fetchUserTokenBalances(vaultAddress);
                const transactionData = await getTransactionData(vaultAddress);
    
                // Extract RTN balance from the tokenBalances
                const rogueToken = tokenBalances.find(token => token.symbol === 'RTN');
                const vaultBalance = rogueToken ? rogueToken.balance : "0";
    
                const newVaultData = {
                    vaultAddress,
                    vaultBalance, // Keep the specific balance for RogueToken (RTN)
                    nftData,
                    transactionData,
                    tokenBalances,
                };
    
                // Only update if the new data differs from the current state
                setVaultData(prevVaultData => {
                    if (JSON.stringify(prevVaultData) !== JSON.stringify(newVaultData)) {
                        return newVaultData;
                    }
                    return prevVaultData;
                });
            } else {
                console.log('Please install MetaMask!');
            }
        } catch (error) {
            console.error('Error fetching vault data:', error);
        }
    };
    
    
    const intervalRef = useRef(null);

    const resetFetchInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(fetchVaultData, 60000); // Set interval to 1 minute (60000 ms)
    };

    useEffect(() => {
        // Initial fetch and set interval
        fetchVaultData();
        resetFetchInterval();

        return () => {
            // Clear the interval when the component unmounts or dependencies change
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [vaultAddress, selectedBlockchain]);

    const forceFetch = () => {
        fetchVaultData();
        resetFetchInterval();
    };


    // Helper function to handle chain switching in the wallet
    const switchBlockchainInWallet = async (blockchain) => {
        const chainParams = blockchainParams[blockchain];
        if (!chainParams) return;
    
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: chainParams.chainId }],
            });
            console.log(`Switched to ${blockchain}`);
        } catch (switchError) {
            // If the chain hasn't been added to the user's wallet, add it
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: chainParams.chainId,
                            chainName: chainParams.chainName,
                            nativeCurrency: chainParams.nativeCurrency,
                            rpcUrls: chainParams.rpcUrls,
                            blockExplorerUrls: chainParams.blockExplorerUrls,
                        }],
                    });
                } catch (addError) {
                    console.error("Failed to add blockchain:", addError);
                }
            } else {
                console.error("Failed to switch blockchain:", switchError);
            }
        }
    };
    
    // Updated blockchain selection handler to switch chains
    const handleBlockchainSelect = (blockchain) => {
        switchBlockchainInWallet(blockchain).then(() => {
            // Only set the blockchain and token if the switch was successful
            setSelectedBlockchain(blockchain);
    
            // Set the native token as default if no other tokens are available
            const token = baseTokens[blockchain] || '';
            setSelectedToken(token);
            setSelectedNFT(null);
    
            localStorage.setItem("selectedBlockchain", blockchain);
            localStorage.setItem("selectedToken", token);
        }).catch((error) => {
            console.error("Blockchain switch failed:", error);
        });
    };
    

    function getBlockchainLogo(blockchain) {
        const logos = {
            Ethereum: ethereumLogo,
            Binance: binanceLogo,
            Polygon: polygonLogo,
            Base: baseLogo
        };
        return logos[blockchain];
    }

    const [tokenEvolutionData, setTokenEvolutionData] = useState({
        Base: {
            isVault: true,
            tokens: {},
            nfts: []
        },
    });

    const [showQRModal, setShowQRModal] = useState(false);

    const fetchNonce = async () => {
        try {
            const provider = new BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const contract = new Contract(vaultAddress, ChainFiVault_ABI, signer);
            
            const wallet_2FA = await contract._2FaAddress();
            const nonce = await contract.getCurrentNonce(wallet_2FA);
            
            return nonce;
        } catch (error) {
            console.error("Failed to fetch nonce:", error);
            return null;
        }
    };

    const [signInMessage, setSignInMessage] = useState('');
    const minimalERC20ABI = [
        "function approve(address spender, uint256 amount) public returns (bool)",
        "function transferFrom(address sender, address recipient, uint256 amount) public returns (bool)",
        "function allowance(address owner, address spender) public view returns (uint256)", // Added allowance function
    ];

    const handleQrCodeInteraction = async (typeOftransaction) => {
        const provider = new BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
    
        const userAddress = await signer.getAddress();
        if (!userAddress) {
            console.error('userAddress is null or undefined.');
            return;
        }
    
        try {
            const transactionNumber = await fetchNonce();
    
            if (transactionNumber === null) {
                console.error("Failed to fetch transaction number, aborting interaction.");
                return;
            }
    
            let message;
            let transactionType;
    
            // Get selected token details from tokenLists
            console.log(selectedToken);
            const tokenList = tokenLists[selectedBlockchain];
            const selectedTokenNow = tokenList.find(token => token.symbol === selectedToken);
    
            if (!selectedTokenNow || !selectedTokenNow.address) {
                console.error('Selected token or its address is null or undefined.');
                return;
            }
    
            if (typeOftransaction === 'deposit') {
                const tokenAddress = ethers.getAddress(selectedTokenNow.address);
                const tokenContract = new ethers.Contract(tokenAddress, minimalERC20ABI, signer);
                const contract = new Contract(vaultAddress, ChainFiVault_ABI, signer); 
    
                if (selectedTokenNow.address === '0x0000000000000000000000000000000000000000') {
                    try {
                        toast.info("Transaction in progress...");
                        const amountforContractReal = amountforContract / 10 ** 18;
                        const depositTx = await contract.depositEther({
                            value: ethers.parseEther(amountforContractReal.toString())
                        });
                        
                        await depositTx.wait();
                        
                        toast.success("Deposit completed successfully.");
                        forceFetch();
                    } catch (error) {
                        toast.error("Error during native token deposit.");
                        console.error("Error during native token deposit:", error);
                    }
                    
                } else {
                    try {
                        const toastIdApproval = toast.info("Checking allowance and approving if needed...", {
                            autoClose: false,
                            position: "top-center"
                        });
    
                        const currentAllowance = await tokenContract.allowance(userAddress, userAddress);
                        const amountForContract = BigInt(Number(amountforContract)).toString();
    
                        if (BigInt(currentAllowance) < BigInt(amountForContract)) {
                            const approvalTx = await tokenContract.approve(userAddress, amountForContract);
                            await approvalTx.wait();
    
                            toast.dismiss(toastIdApproval);
                            toast.success("Approval completed successfully.", { position: "top-center" });
                        } else {
                            console.log("Sufficient allowance already exists, skipping approval.");
                            toast.dismiss(toastIdApproval);
                        }
    
                        const toastIdDeposit = toast.info("Processing deposit transaction...", {
                            autoClose: false,
                            position: "top-center"
                        });
    
                        const transferTx = await contract.depositERC20(tokenAddress, amountForContract);
                        await transferTx.wait();
    
                        toast.dismiss(toastIdDeposit);
                        toast.success("Deposit completed successfully.", { position: "top-center" });
    
                        forceFetch();
                    } catch (error) {
                        toast.dismiss();
                        toast.error("Error during ERC20 token deposit.", { position: "top-center" });
                        console.error("Error during ERC20 token deposit:", error);
                    }
                }
                
            } else if (typeOftransaction === 'withdraw') {
                // Handle Withdraw
                if (mode === 'nfts' && selectedNFT) {
                    const isERC1155 = selectedNFT.amount !== undefined;
    
                    if (!selectedNFT.address) {
                        console.error('selectedNFT.address is null or undefined.');
                        return;
                    }
    
                    transactionType = 'NFT Withdrawal';
                    message = isERC1155
                        ? `${transactionType}, ${selectedNFT.tokenId}, ${selectedNFT.amount}, ${transactionNumber}`
                        : `${transactionType}, ${selectedNFT.tokenId}, ${transactionNumber}`;
    
                }   else if (mode === 'tokens') {
                    // For Ether transactions, the address is set to zero address.
                    const tokenAddress = selectedTokenNow.address === ethers.ZeroAddress
                        ? ethers.ZeroAddress
                        : selectedTokenNow.address;
            
                    const userAddress = await signer.getAddress(); // Fetch the user's address
            
                    if (tokenAddress === ethers.ZeroAddress) {
                        // Handle Ether Withdrawal
                        transactionType = 'Ether Withdrawal';
                        message = `${transactionType}, ${userAddress}, ${amountforContract/(10**18)}, ${transactionNumber}`;
                    } else {
                        // Handle ERC20 Token Withdrawal
                        transactionType = 'ERC20 Token Withdrawal';
                        message = `${transactionType}, ${tokenAddress}, ${userAddress}, ${amountforContract}, ${transactionNumber}`;
                    }
                } 
                 else {
                    console.warn("Invalid type specified or missing required data for QR code interaction.");
                    return;
                }
                console.log(message);

                // Show QR code for withdraw
                setSignInMessage(message || '');
                setShowQRModal(true);
    
            } else if (typeOftransaction === 'transfer') {
                // Handle Transfer
                if (mode === 'nfts' && selectedNFT) {
                    const isERC1155 = selectedNFT.amount !== undefined;
    
                    if (!selectedNFT.address) {
                        console.error('selectedNFT.address is null or undefined.');
                        return;
                    }
    
                    transactionType = 'NFT Transfer';
                    message = isERC1155
                        ? `${transactionType}, ${selectedNFT.tokenId}, ${selectedNFT.amount}, ${transactionNumber}`
                        : `${transactionType}, ${selectedNFT.tokenId}, ${transactionNumber}`;
    
                } else if (mode === 'tokens') {
                    if (!ethers.isAddress(recipientInput)) {
                        console.error("Invalid recipient address.");
                        return;
                    }
                
                    // Determine token address: zero address for Ether, actual address for tokens
                    const tokenAddress = selectedTokenNow.address === '0x0000000000000000000000000000000000000000'
                        ? ethers.ZeroAddress
                        : selectedTokenNow.address;
                
                        if (tokenAddress === ethers.ZeroAddress) {
                            // Handle Ether Transfer
                            transactionType = 'Ether Transfer';
                            // Convert the amount from Wei to Ether for a user-friendly format
                            message = `${transactionType}, ${recipientInput}, ${amountforContract / (10 ** 18)}, ${transactionNumber}`;
                        } else {
                            // Handle ERC20 Token Transfer
                            transactionType = 'ERC20 Token Transfer';
                            // Keep the amount as is for ERC20 tokens (in the token's smallest unit)
                            message = `${transactionType}, ${tokenAddress}, ${recipientInput}, ${amountforContract}, ${transactionNumber}`;
                        }
                }
                 else {
                    console.warn("Invalid type specified or missing required data for QR code interaction.");
                    return;
                }
    
                // Show QR code for transfer
                setSignInMessage(message || '');
                setShowQRModal(true);
    
            } else {
                console.warn("Invalid typeOftransaction specified for QR code interaction.");
            }
        } catch (error) {
            console.error("Error during QR code interaction:", error);
        }
    };
    
    
    const handleSignedMessage = async (signedContent) => {
        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
    
            // Ensure vaultAddress is defined
            if (!vaultAddress) {
                console.error('vaultAddress is null or undefined.');
                toast.error('Vault address is missing.');
                return;
            }
    
            // Get user address
            const userAddress = await signer.getAddress();
            if (!userAddress) {
                console.error('userAddress is null or undefined.');
                toast.error('User address is missing.');
                return;
            }
    
            const contract = new ethers.Contract(vaultAddress, ChainFiVault_ABI, signer);
    
            const nonce = await fetchNonce();
    
            const tokenList = tokenLists[selectedBlockchain];
            const selectedTokenNow = tokenList.find(token => token.symbol === selectedToken);
    
            if (!selectedToken || !selectedTokenNow || !selectedTokenNow.address) {
                console.error('selectedToken or selectedTokenNow.address is null or undefined.');
                toast.error('Token information is missing.');
                return;
            }

            const toastId = toast.info(
                `Executing ${activeTab === 'transfer' ? 'token transfer' : 'token withdrawal'}...`,
                { autoClose: false }
            );
    
            const amountBN = BigInt(Number(amountforContract)).toString();
            const nonceBN = BigInt(Number(nonce)).toString();
            const tokenAddress = ethers.getAddress(selectedTokenNow.address);
            const recipientAddress = activeTab === 'transfer' ? recipientInput : userAddress;
            const vaultAddressString = ethers.getAddress(vaultAddress);
    
            let transaction;
            let signed2faHash;

            if (activeTab === 'transfer') {
                if (tokenAddress === ethers.ZeroAddress) {
                    signed2faHash = ethers.solidityPackedKeccak256(
                        ["address", "uint256", "uint256"],
                        [recipientAddress, amountBN, nonceBN]
                    );
                    console.log(signed2faHash);

                    transaction = await contract.transferEtherWithSignature(
                        recipientAddress,
                        amountBN,
                        nonceBN,
                        signed2faHash,
                        signedContent
                    );
                } else {
                    signed2faHash = ethers.solidityPackedKeccak256(
                        ["address", "address", "uint256", "uint256"],
                        [tokenAddress, recipientAddress, amountBN, nonceBN]
                    );

                    transaction = await contract.transferERC20WithSignature(
                        tokenAddress,
                        recipientAddress,
                        amountBN,
                        nonceBN,
                        signedContent,
                        signed2faHash
                    );
                }
            } else {

                if (tokenAddress === ethers.ZeroAddress) {
                    signed2faHash = ethers.solidityPackedKeccak256(
                        ["address", "uint256", "uint256"],
                        [recipientAddress, amountBN, nonceBN]
                    );

                    transaction = await contract.withdrawEtherWithSignature(
                        amountBN,
                        nonceBN,
                        signed2faHash,
                        signedContent,
                        {gasLimit:500000}
                    );
                } else {
                    signed2faHash = ethers.solidityPackedKeccak256(
                        ["address", "address", "uint256", "uint256"],
                        [tokenAddress, recipientAddress, amountBN, nonceBN]
                    );

                    transaction = await contract.withdrawERC20WithSignature(
                        tokenAddress,
                        amountBN,
                        nonceBN,
                        signedContent,
                        signed2faHash,
                        {gasLimit:500000}
                    );      
                }
            }
            await transaction.wait();
            toast.success(`${activeTab === 'transfer' ? 'Token transfer' : 'Token withdrawal'} successful.`);
            toast.dismiss(toastId);
            forceFetch();
        } catch (error) {
            toast.dismiss();
            toast.error(`${activeTab === 'transfer' ? 'Token transfer' : 'Token withdrawal'} failed.`);
            console.error(`Failed to execute ${activeTab}:`, error);
        } finally {
            setShowQRModal(false);
        }
    };
    

    const createVault = (blockchain) => {
        alert(`Vault created on ${blockchain}. A fee in ${baseTokens[blockchain]} will apply.`);
        setTokenEvolutionData(prevData => ({
            ...prevData,
            [blockchain]: {
                ...prevData[blockchain],
                isVault: true
            }
        }));
    };

    return (
        <div className="Ecosystem-dashboard">
            <h2 className="vault-title">Your Vault: {vaultData.vaultAddress}</h2>
            <div className="vault-overview">

                <div className="vault-info-box">
                    <strong>{mode === 'tokens' ? 'Tokens Stored' : 'NFTs Stored'}: </strong>
                    {mode === 'tokens'
                        ? (vaultData.tokenBalances?.filter(token => parseFloat(token.balance) > 0).length || 0)
                        : (vaultData.nftData?.length || 0)
                    }
                </div>
                <div>
    <select
        className="p-2 border rounded"
        value={mode === 'tokens' ? selectedToken : selectedNFT?.id || 'No NFT'}
        onChange={(e) => {
            if (mode === 'tokens') {
                setSelectedToken(e.target.value);
            } else {
                const selectedNFT = vaultData.nftData?.find(nft => nft.id === e.target.value);
                if (selectedNFT) {
                    setSelectedNFT(selectedNFT);
                } else {
                    setSelectedNFT(null); // Updated to avoid unnecessary "No NFT"
                }
            }
        }}
    >
        {mode === 'tokens'
            ? (
                vaultData.tokenBalances?.length > 0
                    ? [
                        ...vaultData.tokenBalances
                            .filter(token => tokenLists[selectedBlockchain]?.some(t => t.symbol === token.symbol) && parseFloat(token.balance) > 0)
                            .map((token, tokenIndex) => (
                                <option key={`nonZero-${tokenIndex}`} value={token.symbol}>
                                    {token.symbol}: {token.balance}
                                </option>
                            )),
                        ...vaultData.tokenBalances
                            .filter(token => tokenLists[selectedBlockchain]?.some(t => t.symbol === token.symbol) && parseFloat(token.balance) === 0)
                            .map((token, tokenIndex) => ( 
                                <option key={`zero-${tokenIndex}`} value={token.symbol}>
                                    {token.symbol}: {token.balance}
                                </option>
                            )),
                    ]
                    : <option value="">No tokens available</option>
            )
            : (
                vaultData.nftData?.length > 0
                    ? vaultData.nftData
                        .filter(nft => nft.inVault)
                        .map(nft => (
                            <option key={nft.id} value={nft.id}>
                                {nft.name ? nft.name : nft.contractAddress}
                            </option>
                        ))
                    : <option value="No NFT">No NFTs</option>
            )
        }
    </select>
</div>


                <img
                    src={getBlockchainLogo(selectedBlockchain)}
                    alt={selectedBlockchain}
                    className="blockchain-logo"
                    style={{ width: '40px', height: '40px', marginTop: '0px' }}
                />
                <div>
                    <select
                        className="p-2 border rounded select-with-options"
                        value={selectedBlockchain}
                        onChange={(e) => {
                            const newBlockchain = e.target.value;
                            handleBlockchainSelect(newBlockchain);
                            localStorage.setItem('selectedBlockchain', newBlockchain); // Persist the selected blockchain
                        }}
                    >
                        {Object.keys(blockchainParams).map((blockchain, index) => (
                            <option
                                key={index}
                                value={blockchain}
                                className={selectedBlockchain === blockchain ? 'selected-option' : ''}
                            >
                                {blockchain}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <button
                className='switch-nft-token'
                onClick={() => setMode(mode === 'tokens' ? 'nfts' : 'tokens')}
            >
                {mode === 'tokens' ? 'Switch to NFTs' : 'Switch to Tokens'}
            </button>
            <div className='right-and-left'>
                {mode === 'tokens' && (
                    <div style={{ width: "600px", height: "350px" }}>
                        <canvas ref={chartRef} />
                    </div>
                )}
                <div className="trade-box">
                    <div className="tab-buttons">
                        <button
                            className={`tab-button ${activeTab === 'deposit' ? 'active' : ''}`}
                            onClick={() => setActiveTab('deposit')}
                        >
                            Deposit
                        </button>
                        <button
                            className={`tab-button ${activeTab === 'withdraw' ? 'active' : ''}`}
                            onClick={() => setActiveTab('withdraw')}
                        >
                            Withdraw
                        </button>
                        <button
                            className={`tab-button ${activeTab === 'transfer' ? 'active' : ''}`}
                            onClick={() => setActiveTab('transfer')}
                        >
                            Transfer
                        </button>
                    </div>
                    <div className='all-tabs'>
                        {activeTab === 'deposit' && (
                            <div className="tab-content">
                                {!tokenEvolutionData[selectedBlockchain]?.isVault ? (
                                    <div>
                                        <p>You need to create a vault on this chain. A fee in {baseTokens[selectedBlockchain]} will apply.</p>
                                        <button
                                            className="create-vault-button"
                                            onClick={() => {
                                                createVault(selectedBlockchain);
                                            }}
                                        >
                                            Create Vault
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <p>Select the {mode === 'tokens' ? 'amount' : 'NFT'} to deposit:</p>
                                        {mode === 'nfts' && (
                                            <select
                                                className='margin-bottom-20px'
                                                value={selectedNFT?.id || 'No NFT'}
                                                onChange={(e) => {
                                                    const selectedNFT = tokenEvolutionData[selectedBlockchain]?.nfts?.find(nft => nft.id === e.target.value);
                                                    if (selectedNFT) setSelectedNFT(selectedNFT);
                                                }}
                                            >
                                                {tokenEvolutionData[selectedBlockchain]?.nfts?.filter(nft => !nft.inVault)?.length > 0
                                                    ? tokenEvolutionData[selectedBlockchain]?.nfts?.filter(nft => !nft.inVault)?.map(nft => (
                                                        <option key={nft.id} value={nft.id}>
                                                            {nft.name ? nft.name : nft.contractAddress}
                                                        </option>
                                                    ))
                                                    : <option value="No NFT">No NFTs to deposit</option>
                                                }
                                            </select>
                                        )}
                                        {mode === 'tokens' && (
                                            <>
                                                <label>Amount</label>
                                                <input
                                                    type="number"
                                                    placeholder="Enter amount"
                                                    min="0"
                                                    step="any"
                                                    inputMode="numeric"
                                                    onChange={(e) => setAmountForContract(e.target.value* 10**18)}
                                                    onKeyDown={(e) => {
                                                        if (!/^\d*$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', '.'].includes(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}
                                        <button className="submit-button" onClick={() => handleQrCodeInteraction('deposit')}>Deposit</button>
                                    </>
                                )}
                            </div>
                        )}
                        {activeTab === 'withdraw' && (
                            <div className="tab-content">
                                {!tokenEvolutionData[selectedBlockchain]?.isVault ? (
                                    <div>
                                        <p>You need to create a vault on this chain. A fee in {baseTokens[selectedBlockchain]} will apply.</p>
                                        <button
                                            className="create-vault-button"
                                            onClick={() => {
                                                createVault(selectedBlockchain);
                                            }}
                                        >
                                            Create Vault
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <p>Select the {mode === 'tokens' ? 'amount' : 'NFT'} to withdraw:</p>
                                        {mode === 'nfts' && (
                                            <select
                                                className='margin-bottom-20px'
                                                value={selectedNFT?.id || 'No NFT'}
                                                onChange={(e) => {
                                                    const selectedNFT = tokenEvolutionData[selectedBlockchain]?.nfts?.find(nft => nft.id === e.target.value);
                                                    if (selectedNFT) setSelectedNFT(selectedNFT);
                                                }}
                                            >
                                                {tokenEvolutionData[selectedBlockchain]?.nfts?.filter(nft => nft.inVault)?.length > 0
                                                    ? tokenEvolutionData[selectedBlockchain]?.nfts?.filter(nft => nft.inVault)?.map(nft => (
                                                        <option key={nft.id} value={nft.id}>
                                                            {nft.name ? nft.name : nft.contractAddress}
                                                        </option>
                                                    ))
                                                    : <option value="No NFT">No NFTs to withdraw</option>
                                                }
                                            </select>
                                        )}
                                        {mode === 'tokens' && (
                                            <>
                                                <label>Amount</label>
                                                <input
                                                    type="number"
                                                    placeholder="Enter amount"
                                                    min="0"
                                                    step="any"
                                                    inputMode="numeric"
                                                    onChange={(e) => setAmountForContract(e.target.value* 10**18)}
                                                    onKeyDown={(e) => {
                                                        if (!/^\d*$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', '.'].includes(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}
                                        <button className="submit-button" onClick={() => handleQrCodeInteraction('withdraw')}>Withdraw</button>
                                    </>
                                )}
                            </div>
                        )}
                        {activeTab === 'transfer' && (
                            <div className="tab-content">
                                {!tokenEvolutionData[selectedBlockchain]?.isVault ? (
                                    <div>
                                        <p>You need to create a vault on this chain. A fee in {baseTokens[selectedBlockchain]} will apply.</p>
                                        <button
                                            className="create-vault-button"
                                            onClick={() => {
                                                createVault(selectedBlockchain);
                                            }}
                                        >
                                            Create Vault
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <p>Select the {mode === 'tokens' ? 'amount' : 'NFT'} to transfer:</p>
                                        {mode === 'nfts' && (
                                            <div>
                                                <select
                                                    className='margin-bottom-20px'
                                                    value={selectedNFT?.id || 'No NFT'}
                                                    onChange={(e) => {
                                                        const selectedNFT = tokenEvolutionData[selectedBlockchain]?.nfts?.find(nft => nft.id === e.target.value);
                                                        if (selectedNFT) setSelectedNFT(selectedNFT);
                                                    }}
                                                >
                                                    {tokenEvolutionData[selectedBlockchain]?.nfts?.length > 0
                                                        ? tokenEvolutionData[selectedBlockchain]?.nfts?.map(nft => (
                                                            <option key={nft.id} value={nft.id}>
                                                                {nft.name ? nft.name : nft.contractAddress}
                                                            </option>
                                                        ))
                                                        : <option value="No NFT">No NFTs available</option>
                                                    }
                                                </select>
                                                <label>Recipient Address</label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter recipient address"
                                                    inputMode="text"
                                                    value={recipientInput}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        )}
                                        {mode === 'tokens' && (
                                            <>
                                                <label>Recipient Address</label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter recipient address"
                                                    inputMode="text"
                                                    value={recipientInput}
                                                    onChange={handleInputChange}
                                                />
                                                <label>Amount</label>
                                                <input
                                                    type="number"
                                                    placeholder="Enter amount"
                                                    min="0"
                                                    step="any"
                                                    inputMode="numeric"
                                                    onChange={(e) => setAmountForContract(e.target.value * 10**18)}
                                                    onKeyDown={(e) => {
                                                        if (!/^\d*$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', '.'].includes(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}
                                        <button className="submit-button" onClick={() => handleQrCodeInteraction('transfer')}>Transfer</button>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="tab-buttons transaction-history-tabs">
                        <button
                            className={`tab-button ${selectedTimePeriod === '1d' ? 'active' : ''}`}
                            onClick={() => setSelectedTimePeriod('1d')}
                        >
                            1d
                        </button>
                        <button
                            className={`tab-button ${selectedTimePeriod === '7d' ? 'active' : ''}`}
                            onClick={() => setSelectedTimePeriod('7d')}
                        >
                            7d
                        </button>
                        <button
                            className={`tab-button ${selectedTimePeriod === '30d' ? 'active' : ''}`}
                            onClick={() => setSelectedTimePeriod('30d')}
                        >
                            30d
                        </button>
                    </div>
            <h2 className="transactions-title">Transaction History</h2>

            <table className="earning-table">
    <thead>
        <tr>
            <th>Transaction ID</th>
            <th>From Address</th>
            <th>To Address</th>
            <th>Amount</th>
            <th>Timestamp</th>
        </tr>
    </thead>
    <tbody>
    {vaultData.transactionData && vaultData.transactionData.length > 0 ? (
        vaultData.transactionData
            // Sort by timestamp in descending order (latest first)
            .sort((a, b) => b.timeStamp - a.timeStamp)
            // Filter out rows where the amount is zero
            .filter(row => parseInt(row.value, 10) > 0)
            .map((row, index) => {
                // Determine if it's a native ETH transaction or an ERC-20 transfer
                const isEthTransaction = !row.contractAddress; // ETH transactions have no contract address
                const tokenSymbol = isEthTransaction ? 'ETH' : getTokenSymbol(row.contractAddress, 'Base', row.tokenSymbol);
                console.log(index, row);

                const fromAddress = row.from.toLowerCase() === vaultAddress.toLowerCase()
                    ? <span style={{ color: 'green' }}>Vault</span>
                    : <a href={`https://sepolia.basescan.org/address/${row.from}`} target="_blank" rel="noopener noreferrer">
                        {`${row.from.slice(0, 6)}...${row.from.slice(-4)}`}
                      </a>;
                const toAddress = row.to.toLowerCase() === vaultAddress.toLowerCase()
                    ? <span style={{ color: 'green' }}>Vault</span>
                    : <a href={`https://sepolia.basescan.org/address/${row.to}`} target="_blank" rel="noopener noreferrer">
                        {`${row.to.slice(0, 6)}...${row.to.slice(-4)}`}
                      </a>;

                // Calculate the amount: for ETH or ERC-20 tokens
                const amount = parseInt(row.value, 10) / 1e18; // Adjusting for ETH or ERC-20 tokens (assuming 18 decimals)

                return (
                    <tr key={index}>
                        <td>
                            <a href={`https://sepolia.basescan.org/tx/${row.hash}`} target="_blank" rel="noopener noreferrer">
                                {`${row.hash.slice(0, 6)}...${row.hash.slice(-4)}`}
                            </a>
                        </td> {/* Displaying shortened and clickable block hash */}
                        <td>{fromAddress}</td> {/* Displaying shortened or "Vault" from address */}
                        <td>{toAddress}</td> {/* Displaying shortened or "Vault" to address */}
                        <td>{`${amount} ${tokenSymbol}`}</td> {/* Displaying amount with token symbol */}
                        <td>{new Date(row.timeStamp * 1000).toLocaleString()}</td> {/* Converting timestamp */}
                    </tr>
                );
            })
    ) : (
        <tr>
            <td colSpan="6">No transaction data available.</td>
        </tr>
    )}
</tbody>


</table>


            {showQRModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                    <QrCodeModal
                        isOpen={showQRModal}
                        onClose={() => setShowQRModal(false)}
                        signInMessage={signInMessage}
                        onSignedMessage={handleSignedMessage}
                    />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Ecosystem;
